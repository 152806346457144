import { MouseEventHandler, useCallback } from 'react';
import styles from './styles.module.scss';

interface IChipProps {
	label: string;
	handleClose: MouseEventHandler<HTMLElement> | undefined;
	dismissable?: boolean;
}

function Chip(props: IChipProps) {
	const { label, handleClose, dismissable = true } = props;

	const CloseCta = useCallback(() => {
		if (dismissable) {
			return <i onClick={handleClose} className="bi bi-x"></i>;
		}
		return null;
	}, [dismissable, handleClose]);

	return (
		<div
			className={`${styles.chipWrapper} d-inline-flex gap-2 align-items-center rounded px-2 ${dismissable ? '' : 'p-1'}`}
		>
			<p className={`${styles.chipLabel}`}>{label}</p>
			<CloseCta />
		</div>
	);
}

export default Chip;
