import { PropsWithChildren } from 'react';
import style from './styles.module.scss';
import { animated, useSpring } from '@react-spring/web';

interface IBottomSheetProps {
	handleClose: () => void;
}
function BottomSheet(props: PropsWithChildren<IBottomSheetProps>) {
	const [springs, api] = useSpring(() => ({
		from: { y: '100%' },
		to: { y: '0%' },
		onStart: () => {
			document.body.style.overflow = 'hidden';
		},
	}));
	const { handleClose, children } = props;

	function onClickClose() {
		api.start({
			from: { y: '0%' },
			to: { y: '100%' },

			onRest: () => {
				handleClose();
				document.body.style.overflow = 'auto';
			},
		});
	}

	return (
		<div
			className={`vh-100 w-100 d-flex flex-column justify-content-end align-items-center gap-3 position-fixed z-1 ${style.sheetWrapper}`}
		>
			<animated.div
				style={{
					...springs,
				}}
				className={`rounded-top bg-black w-100 p-4 d-flex flex-column ${style.contentWrapper} h-75`}
			>
				<div className="d-flex justify-content-end">
					<button
						onClick={onClickClose}
						className="bg-black border-0"
					>
						<i className="bi bi-x m-0 fs-2 text-white"></i>
					</button>
				</div>
				{children}
			</animated.div>
		</div>
	);
}

export default BottomSheet;
