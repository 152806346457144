function PrivacyPolicy() {
	return (
		<div className="container">
			<div className="mb-4">
				<h1>Privacy Policy</h1>
				<p>
					<strong>Effective Date:</strong> 14th Dec, 2024
				</p>

				<p>
					JusCollab (referred to as “we,” “us,” or “our”) is committed
					to protecting your privacy. This Privacy Policy explains how
					we collect, use, disclose, and safeguard your information
					when you use the JusCollab platform. By using our platform,
					you agree to the terms of this Privacy Policy.
				</p>
			</div>

			<div className="mb-4">
				<h4>Information We Collect</h4>

				<h5>Information You Provide</h5>
				<ul>
					<li>
						<strong>Account Information:</strong> When you log in to
						JusCollab using Instagram, we collect your Instagram
						account details, such as your username, profile picture,
						and user ID.
					</li>
					<li>
						<strong>Submissions:</strong> Content submitted by
						users, such as reviews, product feedback, and related
						media.
					</li>
				</ul>

				<h5>Information Automatically Collected</h5>
				<ul>
					<li>
						<strong>Usage Data:</strong> We collect information
						about your interactions with our platform, including
						timestamps, pages visited, and actions taken.
					</li>
					<li>
						<strong>Device Information:</strong> Information about
						the device you use to access the platform, such as IP
						address, operating system, and browser type.
					</li>
					<li>
						<strong>Information from Instagram:</strong> With your
						permission, we access specific Instagram permissions,
						including:
						<ul>
							<li>
								<strong>instagram_business_basic:</strong> To
								retrieve basic profile details and associate
								actions with your account.
							</li>
							<li>
								<strong>
									instagram_business_content_publish:
								</strong>
								To enable automatic publishing of approved
								submissions on your Instagram account and
								facilitate collaboration with brands.
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>How We Use Your Information</h4>
				<ul>
					<li>
						To authenticate your account and provide secure access
						to the platform.
					</li>
					<li>
						To allow users to submit product reviews and enable
						brands to manage submissions through their admin panel.
					</li>
					<li>
						To link approved submissions to your Instagram profile
						and create posts on your behalf with brand
						collaborations.
					</li>
					<li>
						To improve our platform’s functionality and user
						experience.
					</li>
					<li>
						To comply with legal obligations and protect against
						fraudulent or malicious activities.
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>How We Share Your Information</h4>
				<ul>
					<li>
						<strong>Brands:</strong> Approved submissions, including
						the content you submit and your username, are shared
						with the respective brand for collaboration purposes.
					</li>
					<li>
						<strong>Service Providers:</strong> Third-party vendors
						that assist us with platform operations, such as hosting
						and analytics.
					</li>
					<li>
						<strong>Legal Obligations:</strong> When required by
						law, regulation, or legal process, or to protect our
						rights and interests.
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>Data Retention</h4>
				<p>
					We retain your information as long as necessary to fulfill
					the purposes outlined in this Privacy Policy. If you
					deactivate your account, we will delete or anonymize your
					data, except where retention is required by law or
					legitimate business purposes.
				</p>
			</div>

			<div className="mb-4">
				<h4>Your Rights</h4>
				<p>
					You have the following rights concerning your information:
				</p>
				<ul>
					<li>
						<strong>Access and Portability:</strong> Request a copy
						of the information we have about you.
					</li>
					<li>
						<strong>Correction:</strong> Request corrections to your
						information if it is inaccurate.
					</li>
					<li>
						<strong>Deletion:</strong> Request the deletion of your
						information, subject to applicable legal or business
						requirements.
					</li>
					<li>
						<strong>Withdraw Consent:</strong> Revoke permissions
						granted for Instagram access at any time via Instagram’s
						settings.
					</li>
				</ul>
				<p>
					To exercise your rights, please contact us at{' '}
					<a href="mailto:admin@solebizz.com">admin@solebizz.com</a>.
				</p>
			</div>

			<div className="mb-4">
				<h4>Data Security</h4>
				<p>
					We implement technical, administrative, and physical
					safeguards to protect your information. However, no system
					is completely secure, and we cannot guarantee the absolute
					security of your data.
				</p>
			</div>

			<div className="mb-4">
				<h4>Third-Party Services</h4>
				<p>
					Our platform integrates with Instagram’s API to enable login
					and content publishing functionalities. Your use of
					Instagram services is subject to Instagram’s Privacy Policy.
					We encourage you to review their policies for more
					information.
				</p>
			</div>

			<div className="mb-4">
				<h4>Changes to This Privacy Policy</h4>
				<p>
					We may update this Privacy Policy from time to time. Any
					changes will be posted on this page with the updated
					effective date. Continued use of the platform after changes
					are made signifies your acceptance of the revised Privacy
					Policy.
				</p>
			</div>

			<div className="mb-4">
				<h4>Contact Us</h4>
				<p>
					If you have questions or concerns about this Privacy Policy,
					please contact us at:
				</p>
				<div>
					<p>
						Email:{' '}
						<a href="mailto:admin@solebizz.com">
							admin@solebizz.com
						</a>
					</p>
					<p>
						Address: Sector 76, Tower J, Aditya Urban Casa, Noida,
						Gautam Budh Nagar
					</p>
				</div>
			</div>

			<div className="mb-4">
				<p>
					Thank you for trusting JusCollab. We are committed to
					ensuring your privacy while fostering meaningful
					collaborations between UGC creators, influencers, and
					brands.
				</p>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
