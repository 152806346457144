import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

// type fix for prsist middlewares
type TCustomPersist = (
	config: StateCreator<IUseApp>,
	options: PersistOptions<IUseApp>,
) => StateCreator<IUseApp>;

interface IUseApp {
	APP: App.IData;
	set: (appData: App.IData) => void;
	reset: (appData?: App.IData) => void;
}

const defaultAppData: App.IData = {
	TermsAccepted: false,
};

export const useAppStore = create<IUseApp>(
	(persist as TCustomPersist)(
		set => ({
			APP: defaultAppData,
			set: appData =>
				set(state => ({ APP: { ...state.APP, ...appData } })),
			reset: appData => set({ APP: { ...defaultAppData, ...appData } }),
		}),
		{
			name: 'app-main-store',
			storage: createJSONStorage(() => global.localStorage),
		},
	),
);
