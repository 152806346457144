function TermsOfService() {
	return (
		<>
			<div className="mb-4">
				<h1>Terms of Service</h1>
				<p>
					<strong>Effective Date:</strong> 14th Dec, 2024
				</p>

				<p>
					Welcome to JusCollab.com, a web application that allows
					users to log in with their Instagram accounts, interact with
					Instagram data, and post content to Instagram. By accessing
					or using our web app services, you agree to comply with and
					be bound by these Terms of Service.
				</p>
				<p>
					Please read these Terms carefully. If you do not agree with
					any part of these Terms, you must not use the Service.
				</p>
			</div>

			<div className="mb-4">
				<h4>1. Acceptance of Terms</h4>
				<p>
					By using our Service, you confirm that you are at least 13
					years old (or the applicable minimum age in your
					jurisdiction) and have the legal capacity to enter into a
					binding contract.
				</p>
			</div>

			<div className="mb-4">
				<h4>2. Eligibility</h4>
				<ul>
					<li>
						<strong>Creators and Influencers:</strong> Must log in
						using Instagram.
					</li>
					<li>
						<strong>Brands:</strong> Must register an account on the
						admin portal using Instagram to manage products and
						reviews.
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>3. Platform Usage</h4>
				<ul>
					<li>
						<strong>Creators and Influencers:</strong>
						<ul>
							<li>
								Submit honest and accurate reviews for products.
							</li>
							<li>
								Approved reviews will be posted to your
								Instagram account, and the collaborating brand
								will be tagged in the post.
							</li>
						</ul>
					</li>
					<li>
						<strong>Brands:</strong>
						<ul>
							<li>
								Manage product submissions via the admin panel.
							</li>
							<li>
								Approve or decline submissions, and include
								required details (e.g., coupon codes) during the
								approval process.
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>4. User Responsibilities</h4>
				<p>
					Users must not submit content that violates Instagram’s
					Community Guidelines or JusCollab’s policies. All content,
					including reviews, must be original and free from
					plagiarism.
				</p>
			</div>

			<div className="mb-4">
				<h4>5. Intellectual Property</h4>
				<ul>
					<li>
						Creators retain ownership of their content but grant
						JusCollab and brands a license to use the content for
						collaboration purposes.
					</li>
					<li>
						JusCollab reserves the right to display content for
						promotional or operational purposes.
					</li>
					<li>
						You retain ownership of the content you post to
						Instagram through the Service, but you grant us a
						license to access, display, and interact with that
						content for the purpose of providing the Service.
					</li>
					<li>
						You grant us a worldwide, royalty-free, and
						non-exclusive license to use, display, and process your
						Instagram data in accordance with these Terms and our
						Privacy Policy.
					</li>
					<li>
						All intellectual property rights in the Service,
						including its design, functionality, and content, are
						owned by us or licensed to us. You agree not to infringe
						on these rights.
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>6. Content and Posting</h4>
				<p>
					Approved submissions will be published as posts on the
					creator’s Instagram account. Brands will be added as
					collaborators in the posts. JusCollab is not responsible for
					the accuracy of content created by users.
				</p>
			</div>

			<div className="mb-4">
				<h4>7. Account Suspension and Termination</h4>
				<p>
					To use certain features of our Service, you may be required
					to create an account, including logging in through
					Instagram. You agree to provide accurate, current, and
					complete information during the registration process and
					keep your account information up to date. You are
					responsible for maintaining the confidentiality of your
					account login details, including your Instagram login
					credentials, and for all activities that occur under your
					account.
				</p>
				<p>
					<strong>Account Termination:</strong> We may suspend or
					terminate your account if we believe you are violating these
					Terms, engaging in prohibited conduct, or using the Service
					in a way that could harm other users or our platform.
				</p>
			</div>

			<div className="mb-4">
				<h4>8. Third-Party Services</h4>
				<p>
					The Service integrates with third-party services, such as
					Instagram via the Meta API, and may contain links to
					third-party websites or resources. You acknowledge and agree
					that:
				</p>
				<ul>
					<li>
						We are not responsible for the content, products, or
						services provided by third parties.
					</li>
					<li>
						Your use of third-party services is governed by the
						respective terms and privacy policies of those third
						parties.
					</li>
					<li>
						Instagram (Meta) is a third-party provider and is not
						responsible for the operation or availability of our
						Service.
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>9. Limitation of Liability</h4>
				<p>
					JusCollab is not liable for any damages resulting from
					platform use, including but not limited to:
				</p>
				<ul>
					<li>Disputes between influencers and brands.</li>
					<li>
						Issues with Instagram account access or permissions.
					</li>
				</ul>
			</div>

			<div className="mb-4">
				<h4>10. Delete account data.</h4>
				<p>Follow these steps to delete your account data:</p>
				<ol>
					<li>
						Login to Juscollab application (If not already logged
						in).
					</li>
					<li>Click on the hamburger menu icon on top right.</li>
					<li>Select "Delete my data" option.</li>
					<li>Confirm your choice in the bottom popup.</li>
					<li>Your data will immediately deleted as you confirm</li>
				</ol>
			</div>

			<div className="mb-4">
				<h4>11. Modifications to the Terms</h4>
				<p>
					We may update these Terms of Service at any time. Continued
					use of the platform constitutes acceptance of the updated
					terms.
				</p>
			</div>

			<div className="mb-4">
				<h4>12. Contact Us</h4>
				<p>
					For questions about these Terms of Service, please contact
					us at{' '}
					<a href="mailto:admin@solebizz.com">admin@solebizz.com</a>.
				</p>
			</div>
		</>
	);
}

export default TermsOfService;
