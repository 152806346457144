import { useMemo } from 'react';
import styles from './styles.module.scss';
import { IProductInfo } from '../../utils/apis';
import { useAppStore } from '../../store/appMain';

interface IProductCardProps
	extends Pick<IProductInfo, 'name' | 'image' | 'rewards'> {
	status?: string;
	showDescription?: boolean;
	showImage?: boolean;
}

function ProductCard(props: IProductCardProps) {
	const {
		name,
		image,
		rewards,
		status = '',
		showDescription = true,
		showImage = true,
	} = props;

	const { APP } = useAppStore(state => state);

	const renderRewards = useMemo(() => {
		if (!rewards) return '';

		const text = rewards.map(reward => reward.subtitle).join(' + ');
		return text;
	}, [rewards]);

	const renderDescription = useMemo(() => {
		if (!showDescription) return null;

		return (
			<>
				<p className="fw-bold">Rewards* :</p>
				<p className={styles.description}>{renderRewards}</p>
			</>
		);
	}, [showDescription, renderRewards]);

	const renderImage = useMemo(() => {
		if (!showImage) return null;

		return (
			<div
				className={`${styles.image} rounded`}
				style={{
					backgroundImage: `url(${image})`,
				}}
			/>
		);
	}, [image, showImage]);

	const renderStatus = useMemo(() => {
		const statusClasses =
			status === 'Approved'
				? 'text-white bg-success border-success'
				: status === 'Declined'
					? 'border-primary bg-primary text-white'
					: 'text-primary bg-white border-primary';
		return (
			<span
				className={`${styles.productStatus} px-2 ${statusClasses} border text-center rounded-pill `}
			>
				{status}
			</span>
		);
	}, [status]);

	return (
		<div
			className={`${styles.productCardWraper} border-dark-subtle d-flex gap-3 items-center justify-content-between p-3 rounded w-10`}
		>
			<div className="d-flex flex-column justify-content-between">
				<div>
					<p className="m-0 fs-5 fw-bolder">{APP.Agency?.Name}</p>
					<p className="m-0 fs-6">{name}</p>
				</div>

				<div>{renderDescription}</div>
			</div>
			<div className="d-flex flex-column align-items-center gap-2">
				{renderStatus}
				<div
					className={`${styles.imageWrapper} d-flex flex-column align-items-end`}
				>
					{renderImage}
				</div>
			</div>
		</div>
	);
}

export default ProductCard;
