import styles from './styles.module.scss';
function Required() {
	return (
		<span className={`${styles.required} mx-1 text-primary`}>
			(Required*)
		</span>
	);
}

export default Required;
