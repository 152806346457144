import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SSO() {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate('/welcome', { replace: true });
		}, 3000);
	}, [navigate]);

	return (
		<div className="d-flex align-items-center justify-content-center h-100">
			<p>Logging in..</p>
		</div>
	);
}

export default SSO;
