function Success() {
	return (
		<div className="d-flex align-items-center justify-content-center h-100 flex-column">
			<p className="text-center">
				Submitted. Your Post is awaiting the approval. ⏳
			</p>
			<p>
				Check your submissions <a href="/post-list">here</a>.
			</p>
		</div>
	);
}

export default Success;
