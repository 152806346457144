import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { useAppStore } from '../../../store/appMain';

function Secure(props: PropsWithChildren) {
	const { children } = props;
	const { APP } = useAppStore(state => state);
	const navigate = useNavigate();

	function handleClick() {
		navigate('/');
	}

	if (!APP.Auth) {
		return (
			<div className="d-flex flex-column align-items-center justify-content-center h-100 gap-3">
				<p>User not logged in. ⚠️</p>
				<button
					onClick={handleClick}
					type="submit"
					className="w-50 border rounded-pill d-flex align-items-center btn d-flex items-center justify-content-center gap-2 p-2 px-3 bg-white rounded border-primary"
				>
					<p className="m-0 fw-bold text-primary">
						Login To Continue
					</p>
				</button>
			</div>
		);
	}

	return <>{children}</>;
}

export default Secure;
