import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './app/home';
import SSO from './app/wip/sso';
import Welcome from './app/welcome';
import UploadVideo from './app/post-review';
import Success from './app/wip/success';
import Auth from './app/oauth/auth';
import Final from './app/oauth/final';
import PostList from './app/post-list';
import Secure from './lib/components/Layout/Secure';
import Main from './lib/components/Layout/Main';
import PageNotFound from './app/wip';
import ErrorElement from './lib/components/ErrorElement';
import PrivacyPolicy from './app/privacy-policy';
import TermsOfService from './app/terms-of-service';

function App() {
	const router = createBrowserRouter([
		{
			path: '/',
			errorElement: <ErrorElement />,
			element: <Main />,
			children: [
				{
					index: true,
					element: <Home />,
				},
				{
					path: '/wip/sso',
					element: <SSO />,
				},
				{
					path: '/wip/success',
					element: <Success />,
				},
				{
					path: '/oauth/auth',
					element: <Auth />,
				},
				{
					path: '/oauth/final',
					element: <Final />,
				},
				{
					path: '/privacy-policy',
					element: <PrivacyPolicy />,
				},
				{
					path: '/terms-of-service',
					element: <TermsOfService />,
				},
			],
		},
		{
			path: '/',
			errorElement: <ErrorElement />,
			element: (
				<Secure>
					<Main />
				</Secure>
			),
			children: [
				{
					path: '/welcome',
					element: <Welcome />,
				},
				{
					path: '/post-list',
					element: <PostList />,
				},
				{
					path: '/post-review',
					element: <UploadVideo />,
				},
			],
		},
		{
			path: '*',
			errorElement: <ErrorElement />,
			element: (
				<Main>
					<PageNotFound />
				</Main>
			),
		},
	]);

	return (
		<div className="d-flex flex-column align-items-center vh-100">
			<div className="main-width bg-black h-100 p-4 overflow-auto">
				<RouterProvider router={router} />
			</div>
		</div>
	);
}

export default App;
