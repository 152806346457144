import React, { FormEvent } from 'react';
import Chip from '../../../../lib/components/Chip';
import styles from './styles.module.scss';
import Required from '../../../../lib/components/Required';

interface IHashtags {
	id: string;
	label: string;
	dismissable: boolean;
}
interface IHashtagsSectionProps {
	hashtags: IHashtags[];
	handleHashtagClose: (id: string) => void;
	handleAddCustomHashtag: (event: FormEvent) => void;
	hastagInputText: string;
	handleOnChangeHashtagText: (text: string) => void;
}
function HashtagsSection(props: IHashtagsSectionProps) {
	const {
		hashtags,
		handleHashtagClose,
		handleAddCustomHashtag,
		hastagInputText,
		handleOnChangeHashtagText,
	} = props;
	return (
		<div>
			<li className="fw-bold">
				Hashtags
				<Required />
			</li>
			<div
				className={`d-flex flex-wrap gap-2 mt-2 p-2 rounded ${styles.hashtagsWrapper}`}
			>
				{hashtags.map(hashtag => {
					return (
						<div key={hashtag.id}>
							<Chip
								label={'#' + hashtag.label}
								dismissable={hashtag.dismissable}
								handleClose={() =>
									handleHashtagClose(hashtag.id)
								}
							/>
						</div>
					);
				})}
				<form onSubmit={handleAddCustomHashtag}>
					<input
						value={hastagInputText}
						onChange={e =>
							handleOnChangeHashtagText(e.target.value)
						}
						className={`${styles.hashtagInput} border-0 rounded px-1`}
						type="text"
						placeholder="Add your own"
					/>
				</form>
			</div>
		</div>
	);
}

export default React.memo(HashtagsSection);
