import { useAppStore } from '../store/appMain';
import { serializeResponse } from './globals';

interface IAuthInfo {
	rt_token: string;
	at_token: string;
	at_expiry: number;
	rt_expiry: number;
}

interface IAppSignInInfo extends App.IAuth, App.IUser {}

interface ISignInUSerProps {
	code: string;
	redirectURL: string;
}
// Get userinfo and auth details ✅
export async function signInUser(
	params: ISignInUSerProps,
): Promise<IAppSignInInfo | undefined> {
	interface ISigninInfo extends IAuthInfo {
		user_id: string;
		username: string;
		name: string;
		account_type: string;
		profile_picture_url: string;
	}
	const { code, redirectURL } = params;
	const body = {
		code,
		user_role: 'creator',
		redirectURL,
	};
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	try {
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/public/signIn`,
			{
				method: 'POST',
				headers,
				body: JSON.stringify(body),
				redirect: 'follow',
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		const data = jsonResp.data as ISigninInfo;

		const serializedSignInInfo = serializeResponse<
			IAppSignInInfo,
			ISigninInfo
		>(data, {
			AccessToken: 'at_token',
			RefreshToken: 'rt_token',
			AccessExpires: 'at_expiry',
			RefreshExpires: 'rt_expiry',
			UserId: 'user_id',
			Username: 'username',
			AccountType: 'account_type',
			Name: 'name',
			Image: 'profile_picture_url',
		});
		return serializedSignInInfo;
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

// Get ageny by key ✅
export async function getAgencyById(id: string) {
	interface IAgencyInfo {
		_id: string;
		name: string;
		image: string;
		agencyKey: string;
		hashtags: string[];
	}
	try {
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/public/agency/${id}`,
			{
				method: 'GET',
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		const data = jsonResp.data as IAgencyInfo;
		const serializedAgencyInfo = serializeResponse<
			App.IAgencyInfo,
			IAgencyInfo
		>(data, {
			ID: '_id',
			Name: 'name',
			AgencyKey: 'agencyKey',
			Image: 'image',
			Hashtags: 'hashtags',
		});
		return serializedAgencyInfo;
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

// Fetch Auth Detials given that user is signed in ✅
export async function refreshToken(force = false) {
	try {
		const { APP, set } = useAppStore.getState();
		if (
			!APP.Auth ||
			!APP.Auth.AccessExpires ||
			!APP.Auth.RefreshExpires ||
			!APP.Auth.AccessToken ||
			!APP.Auth.RefreshToken
		)
			throw Error('Login first. ⛔️');

		const currentTime = new Date().getTime();

		let serialisedNewAuthInfo: App.IAuth;

		// fetch token if force or time > access token
		if (force || currentTime > APP.Auth.AccessExpires) {
			const headers = {
				rt_token: APP.Auth.RefreshToken,
				at_token: APP.Auth.AccessToken,
			};
			const res = await fetch(
				`${process.env.REACT_APP_ADMIN_URL}/public/refresh-token`,
				{
					method: 'GET',
					headers,
				},
			);
			const jsonResp: unknown = await res.json();
			if (!jsonResp || typeof jsonResp !== 'object')
				throw Error('Server error. Not an object. ⛔️');
			if (
				res.status !== 200 &&
				'message' in jsonResp &&
				typeof jsonResp.message === 'string'
			)
				throw Error(jsonResp.message);
			if (
				!('data' in jsonResp) ||
				typeof jsonResp.data !== 'object' ||
				!jsonResp.data
			)
				throw Error('Server error. ⛔️');

			const newAuthInfo = jsonResp.data as IAuthInfo;

			serialisedNewAuthInfo = serializeResponse<App.IAuth, IAuthInfo>(
				newAuthInfo,
				{
					AccessToken: 'at_token',
					RefreshToken: 'rt_token',
					AccessExpires: 'at_expiry',
					RefreshExpires: 'rt_expiry',
				},
			);
		} else {
			// return stored auth
			serialisedNewAuthInfo = APP.Auth;
		}
		set({
			Auth: serialisedNewAuthInfo,
		});
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

export interface IRewards {
	type?: string;
	title?: string;
	subtitle?: string;
}
export interface IProductInfo {
	_id: string;
	caption?: string;
	image?: string;
	name?: string;
	rewards?: IRewards[];
	hashtags: string[];
}
// Fetch product info by ID ✅
export async function getProductById(
	id: string,
): Promise<IProductInfo | undefined> {
	try {
		const { APP } = useAppStore.getState();
		const headers = {
			at_token: APP.Auth?.AccessToken || '',
			agencyKey: APP.Agency?.AgencyKey || '',
		};
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/dynamic/item/${id}`,
			{
				method: 'GET',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return jsonResp.data as IProductInfo;
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

interface IAppUploadInfo {
	id: string;
	progress: number;
}
// Start the upload ✅
export async function uploadInit(
	file: FormData,
): Promise<IAppUploadInfo | undefined> {
	interface IUploadInfo {
		_id: string;
		uploadProgress: number;
	}
	try {
		const { APP } = useAppStore.getState();
		refreshToken();
		const headers = {
			at_token: APP.Auth?.AccessToken ?? '',
		};
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/upload`,
			{
				method: 'POST',
				headers,
				body: file,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		const data = jsonResp.data as IUploadInfo;
		const serialisedUploadInfo = serializeResponse<
			IAppUploadInfo,
			IUploadInfo
		>(data, {
			id: '_id',
			progress: 'uploadProgress',
		});
		return serialisedUploadInfo;
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

export interface IAppUploadProgressInfo extends IAppUploadInfo {
	url?: string;
}
// check status and get the upload url ✅
export async function checkUploadStatus(
	id: string,
): Promise<IAppUploadProgressInfo | undefined> {
	interface IUploadProgressInfo {
		_id: string;
		uploadProgress: number;
		url?: string;
	}
	try {
		const { APP } = useAppStore.getState();
		const headers = {
			at_token: APP.Auth?.AccessToken || '',
		};
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/upload/${id}`,
			{
				method: 'GET',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');
		const data = jsonResp.data as IUploadProgressInfo;
		const serialisedUploadProgressInfo = serializeResponse<
			IAppUploadProgressInfo,
			IUploadProgressInfo
		>(data, {
			id: '_id',
			progress: 'uploadProgress',
			url: 'url',
		});
		return serialisedUploadProgressInfo;
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

interface IPostReview {
	productId: string;
	videoId: string;
	caption: string;
}
// Submit post for approval ✅
export async function postReview(params: IPostReview) {
	const { productId, videoId, caption } = params;
	const body = {
		productId,
		videoId,
		caption,
	};
	const { APP } = useAppStore.getState();
	const headers = {
		at_token: APP.Auth?.AccessToken || '',
		agencyKey: APP.Agency?.AgencyKey || '',
		redirect: 'follow',
		'Content-Type': 'application/json',
	};
	try {
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/post`,
			{
				method: 'POST',
				headers,
				body: JSON.stringify(body),
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return { message: 'Post Submitted' };
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

interface IPostInfo {
	_id: string;
	caption: string;
	rewards: IRewards[];
	productName: string;
	agencyName: string;
	productImage: string;
	status: string;
	createdAt: string;
}
export interface IPostListInfo {
	posts: IPostInfo[];
	count: number;
}
// get all subissions ✅
export async function postList() {
	try {
		const { APP } = useAppStore.getState();
		const headers = {
			at_token: APP.Auth?.AccessToken || '',
			agencyKey: APP.Agency?.AgencyKey || '',
		};
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/post`,
			{
				method: 'GET',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return jsonResp.data as IPostListInfo;
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}

// delete user data ✅
export async function deleteUserData() {
	try {
		const { APP } = useAppStore.getState();
		const headers = {
			at_token: APP.Auth?.AccessToken || '',
		};
		const res = await fetch(
			`${process.env.REACT_APP_ADMIN_URL}/secure/delete`,
			{
				method: 'DELETE',
				headers,
			},
		);
		const jsonResp: unknown = await res.json();
		if (!jsonResp || typeof jsonResp !== 'object')
			throw Error('Server error. Not an object. ⛔️');
		if (
			res.status !== 200 &&
			'message' in jsonResp &&
			typeof jsonResp.message === 'string'
		)
			throw Error(jsonResp.message);
		if (
			!('data' in jsonResp) ||
			typeof jsonResp.data !== 'object' ||
			!jsonResp.data
		)
			throw Error('Server error. ⛔️');

		return null;
	} catch (e) {
		// TODO store logs
		console.error((e as Error).message);
	}
}
