import styles from './styles.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAgencyById } from '../../lib/utils/apis';
import { useAppStore } from '../../lib/store/appMain';

function Home() {
	const { set, APP } = useAppStore(state => state);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const agencyId = searchParams.get('agency') || '';
	const productId = searchParams.get('product') || '';
	const [isChecked, setIsChecked] = useState(APP.TermsAccepted ?? false);
	const [loading, setLoading] = useState(false);

	const agencyImage = agencyId ? APP.Agency?.Image : '/logo192.png';

	function handleChangeTnC() {
		setIsChecked(prev => {
			set({ TermsAccepted: !prev });
			return !prev;
		});
	}

	function handleSubmit() {
		navigate('/oauth/auth');
	}

	const getAgencyDetails = useCallback(
		async (id: string) => {
			setLoading(true);
			const agencyInfo = await getAgencyById(id);
			set({
				Agency: agencyInfo,
			});
			setLoading(false);
		},
		[set, setLoading],
	);

	const handleLoggedinUserSubmit = useCallback(() => {
		if (!productId) return navigate('/welcome');

		const params = new URLSearchParams();
		params.set('product', productId);
		navigate(`/welcome?${params.toString()}`);
	}, [navigate, productId]);

	const renderLoggedinView = useMemo(() => {
		if (!APP.Auth) {
			return null;
		}

		return (
			<>
				<p>OR</p>
				<button
					onClick={handleLoggedinUserSubmit}
					type="submit"
					disabled={!isChecked}
					className={`${!isChecked && 'opacity-50'} w-75 border rounded-pill d-flex align-items-center btn d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded`}
				>
					<p className="m-0">
						Continue With{' '}
						<span className="fw-bold">{APP.User?.Name}</span>
					</p>
				</button>
			</>
		);
	}, [isChecked, APP.User?.Name, handleLoggedinUserSubmit, APP.Auth]);

	const renderAgencyText = useMemo(() => {
		if (!APP.Agency?.Name) return null;

		return (
			<p className="fs-4 text-center mt-2">
				<span className="fs-6">You are invited by</span>
				<br />
				{APP.Agency?.Name}
			</p>
		);
	}, [APP.Agency?.Name]);

	useEffect(() => {
		if (agencyId) getAgencyDetails(agencyId);
		else set({ Agency: undefined });

		// passing this in the query params after login success
		if (productId)
			set({
				ProductId: productId,
			});
		else
			set({
				ProductId: undefined,
			});
	}, [agencyId, getAgencyDetails, productId, set]);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100">
				<p>Loading...</p>
			</div>
		);
	}

	return (
		<div className="d-flex flex-column align-items-center gap-4 mt-5">
			<div className="d-flex flex-column align-items-center">
				<p className="fs-5 fw-bold">Welcome IGers to JusCollab</p>
				{renderAgencyText}
			</div>
			<div className="d-flex flex-column">
				<div
					className={`${styles.logoImage} align-self-center rounded-pill border border-4`}
					style={{
						backgroundImage: `url(${agencyImage})`,
					}}
				/>
			</div>
			<div className="d-flex flex-column align-items-center gap-3">
				<p className="text-center">
					Where customers and brands grow together.
				</p>
				<div className="">
					<input
						id="tnc"
						type="checkbox"
						checked={isChecked}
						onChange={handleChangeTnC}
					></input>
					<label htmlFor="tnc">
						<p className="mx-2">
							I accept all{' '}
							<a href="/terms-of-service" target="_blank">
								terms and conditions.
							</a>
						</p>
					</label>
				</div>
				<button
					onClick={handleSubmit}
					type="submit"
					disabled={!isChecked}
					className={`${!isChecked && 'opacity-50'} w-75 rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded`}
				>
					<p className="m-0">
						<i className="bi bi-instagram me-1"></i> Sign in with
						Instagram
					</p>
				</button>
				{renderLoggedinView}
			</div>
			<p className={`${styles.poweredBy}`}>
				Powered by
				<a href="https://solebizz.com" target="_blank" className="mx-1">
					Solebizz
				</a>
			</p>
		</div>
	);
}

export default Home;
