import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signInUser } from '../../../lib/utils/apis';
import { useAppStore } from '../../../lib/store/appMain';
import { getOauthRedirectUrl } from '../../../lib/utils/globals';

function Final() {
	const { set, APP } = useAppStore(state => state);
	const [searchParams] = useSearchParams();
	const [isError, setIsError] = useState(false);
	const [timer, setTimer] = useState(3);
	const intervalRef = useRef<NodeJS.Timer>();
	const errorRef = useRef({
		reason: 'Something went wrong.',
	});
	const [shouldRedirect, setShouldRedirect] = useState(false);
	const navigate = useNavigate();

	function handleClick() {
		navigate('/');
	}

	const startTimer = useCallback(function startTimer() {
		intervalRef.current = setInterval(() => {
			setTimer(prev => prev - 1);
		}, 1000);
	}, []);

	const getAuthDetails = useCallback(
		async function getAuthDetails() {
			try {
				const code = searchParams.get('code');
				const error = searchParams.get('error');
				const errorDescription =
					searchParams.get('error_description') ||
					'Something went wrong';

				if (error) {
					errorRef.current.reason = errorDescription;
					throw Error();
				}
				if (!code) {
					startTimer();
					setShouldRedirect(true);
					return;
				}

				const redirectURL = getOauthRedirectUrl();
				const params = {
					code,
					redirectURL,
				};
				const signInDetails = await signInUser(params);
				if (!signInDetails) throw Error();

				const {
					AccessToken,
					RefreshToken,
					AccessExpires,
					RefreshExpires,
					UserId,
					Username,
					Name,
					AccountType,
					Image,
				} = signInDetails;

				const authDetails = {
					AccessToken,
					RefreshToken,
					AccessExpires,
					RefreshExpires,
				};
				const userDetails = {
					UserId,
					Name,
					Username,
					AccountType,
					Image,
				};
				set({
					Auth: authDetails,
					User: userDetails,
				});
				const productID = APP.ProductId;
				if (productID) {
					const searchParams = new URLSearchParams();
					searchParams.set('product', productID);
					navigate(`/welcome?${searchParams.toString()}`);
				} else {
					navigate('/welcome');
				}
			} catch (e) {
				setIsError(true);
				console.error(e);
			}
		},
		[searchParams, set, navigate, startTimer, APP.ProductId],
	);

	useEffect(() => {
		getAuthDetails();
	}, [getAuthDetails]);

	useEffect(() => {
		if (timer === 1) {
			clearInterval(intervalRef.current);
			window.location.href = 'https://juscollab.com';
		}
	}, [timer]);

	if (isError) {
		return (
			<div className="d-flex flex-column align-items-center justify-content-center h-100 gap-3">
				<p>{errorRef.current.reason}. Please login again.</p>
				<button
					onClick={handleClick}
					type="submit"
					className="w-50 border rounded-pill d-flex align-items-center btn d-flex items-center justify-content-center gap-2 p-2 px-3 bg-white rounded border-primary"
				>
					<p className="m-0 fw-bold text-primary">Login</p>
				</button>
			</div>
		);
	}

	if (shouldRedirect) {
		return (
			<div className="d-flex flex-column align-items-center justify-content-center h-100 gap-3">
				<p>Redirecting to Juscollab in {timer}</p>
			</div>
		);
	}

	return (
		<div className="d-flex align-items-center justify-content-center h-100">
			<p>Authenticating...</p>
		</div>
	);
}

export default Final;
